import '../components/landingpage/animationBlock.css';
import '../components/landingpage/animationFeedback.css';
import '../components/landingpage/hero.css';

import * as theme from '../styles/ovsrTheme';

import {
  Alert,
  ChakraProvider,
  ColorModeScript,
  Container,
  Highlight,
  Text,
  ThemeConfig
} from '@chakra-ui/react';
import type { AppProps } from 'next/app';

import { extendTheme } from '@chakra-ui/react';
import { ClerkProvider } from '@clerk/nextjs';
import AmplitudeContextProvider from 'components/Amplitude/AmplitudeContext';
import { ApolloProviderWrapper } from 'components/ApolloWraper';
import CookieConsent from 'components/hooks';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import NavBar from '../components/navbar/navbar';
import { Fonts } from '../fonts';
import { GoogleAnalytics } from '@next/third-parties/google'






if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: "/ingest",
    ui_host: "https://us.posthog.com",
    person_profiles: 'identified_only',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    }
  })
}

function App({ Component, pageProps }: AppProps) {
  // const ovsrTheme = extendTheme(theme);
  const router = useRouter()

  const newtheme: ThemeConfig = extendTheme({
    initialColorMode: 'dark',
    useSystemColorMode: false,
    styles: {
      global: {
        body: {
          bgGradient: 'linear(to-br, #18132c, purple.800)'
        },
        '.legal': {
          h1: {
            fontSize: 'xl',
            mb: '4',
            fontWeight: 'bold',
          },
          h2: {
            fontSize: 'xl',
            fontWeight: 'bold',
            mb: '1',
            mt: '6',
          },
          h3: {
            fontSize: 'lg',
            mb: '4',
          },
          ol: {
            mb: '2',
          },
          li: {
            fontSize: 'sm',
            ml: '10',
          },
          a: {
            color: '#0969DA',
            'text-decoration-style': 'dotted',
          },
          'a:hover': {
            textDecoration: 'underline',
          },
          p: {
            fontSize: 'sm',
            my: '2',
          },
        },
      },
    },
    colors: {
      RaisnBlack: {
        50: '#736995',
        100: '#5c5477',
        200: '#504968',
        300: '#453f59',
        400: '#39344a',
        500: '#221F2C',
        600: '#0b0a0e',
        700: '#000000',
        800: '#000000',
        900: '#000000',
      },
      RussianViolet: {
        '50': '#907bc2',
        '100': '#7359b2',
        '200': '#664ca5',
        '300': '#5b4493',
        '400': '#513c82',
        '500': '#3b2c5f',
        '600': '#251c3c',
        '700': '#1b142b',
        '800': '#100c19',
        '900': '#000000',
      },
      EnglishViolet: {
        '50': '#a89bc5',
        '100': '#8c7bb2',
        '200': '#7e6ba9',
        '300': '#705c9e',
        '400': '#65538e',
        '500': '#4e406e',
        '600': '#372d4e',
        '700': '#2c243e',
        '800': '#201a2e',
        '900': '#09080d',
      },
      DarkViolet: {
        '50': '#e48aff',
        '100': '#d857ff',
        '200': '#d33dff',
        '300': '#cd23ff',
        '400': '#c70aff',
        '500': '#a500d6',
        '600': '#7e00a3',
        '700': '#6a008a',
        '800': '#560070',
        '900': '#2f003d',
      },
      SeaSalt: {
        '50': '#ffffff',
        '100': '#ffffff',
        '200': '#ffffff',
        '300': '#ffffff',
        '400': '#ffffff',
        '500': '#f7fafc',
        '600': '#d2e3ee',
        '700': '#bfd7e7',
        '800': '#adcce0',
        '900': '#88b4d2',
      },
    },
    fonts: {
      heading: 'Montserrat',
      body: 'Roboto',
    },
    ...theme,
  });

  const defaultSeoConfig = {
    openGraph: {
      url: 'https://cohezion.ai/',
      title: 'COHEZION',
      description: 'Bringing studios and gamers closer together.',
      images: [
        {
          url: '/images/ez_logo.png',
          width: 800,
          height: 600,
          alt: 'cohezion avatar',
          type: 'image/png',
        },
      ],
      siteName: 'COEHZION',
    },
  };


  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <>
      <Head>
        <link rel='shortcut icon' href='./images/ez_avat.png' />
        <script 
          src="https://analytics.ahrefs.com/analytics.js" 
          data-key="tErs5RZCDaO5dNZhhWz7Kg" 
          async
        />
      </Head>
      <ColorModeScript initialColorMode={'dark'} />
      <ChakraProvider theme={newtheme}>
        <Fonts />

        <ClerkProvider
          {...pageProps}
          toastOptions={{
            position: 'bottom-right',
            variant: 'left-accent',
            duration: 5000,
            isClosable: true,
          }}
        >
          <ApolloProviderWrapper>
            <AmplitudeContextProvider>
              <PostHogProvider client={posthog}>
                <NavBar {...pageProps} />
                <DefaultSeo openGraph={defaultSeoConfig.openGraph} />
                <Component {...pageProps} />
                <GoogleAnalytics gaId="G-KVFM2C7PG8" />
                <CookieConsent />
              </PostHogProvider>
            </AmplitudeContextProvider>
          </ApolloProviderWrapper>
        </ClerkProvider>
      </ChakraProvider>
    </>
  );
}

export default App;
