import React, { useEffect } from 'react';

const CookieConsent = () => {
  useEffect(() => {
    // Create script element for cconsent
    const script = document.createElement('script');
    script.src = 'https://cdn.websitepolicies.io/lib/cconsent/cconsent.min.js';
    script.defer = true;

    // Append script to the body
    document.body.appendChild(script);

    // Initialize the cookie consent banner after the script loads
    script.onload = () => {
      setTimeout(() => {
        window.wpcb.init({
          colors: {
            popup: {
              background: '#222222',
              text: '#ffffff',
              border: '#000',
            },
            button: { background: '#f9f9f9', text: '#000000' },
          },
          position: 'bottom',
          border: 'normal',
          corners: 'normal',
          transparency: 5,
          content: {
            href: 'https://cohezion.ai/legal/cookie-policy',
          },
        });
      }, 1000);
    };

    // Remove script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default CookieConsent;
